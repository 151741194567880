



































































































































import Vue, { VueConstructor } from 'vue'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'

import BalanceCard from '@/components/BalanceCard.vue'
import MoneyTransferForm from '@/components/MoneyTransferForm.vue'
import OtpModal from '@/components/OtpModal.vue'

import CreditLine from '../../../store/models/CreditLine'
import CreditLineDeadline from '../../../store/models/CreditLineDeadline'
import Organization from '../../../store/models/Organization'
import uiState from '../../../ui-state'
import AccountMixin from '../../../mixins/accountMixin'
import Account from '../../../store/models/Account'
import { mapGetters } from 'vuex'

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      otpModal: InstanceType<typeof OtpModal>
    }
  } & InstanceType<typeof AccountMixin>
>).extend({
  components: {
    OtpModal,
    BalanceCard,
    MoneyTransferForm
  },

  mixins: [AccountMixin],

  data() {
    return {
      activeDeadlineId: null,
      selectedCreditLineId: null,
      form: {
        otm: 'mtn',
        amount: 0
      },
      uiState: uiState([
        'idle',
        'requesting',
        'validating_otp',
        'checking_status',
        'transaction_successful',
        'error_low_balance',
        'error_request_otp',
        'error_transaction_failed'
      ])
    }
  },

  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    requesting(): boolean {
      return this.uiState.is('requesting')
    },

    showLowBalanceError(): boolean {
      return this.uiState.is('error_low_balance')
    },

    showSuccessfullMessage(): boolean {
      return this.uiState.is('transaction_successful')
    },

    showOtpModal(): boolean {
      return this.uiState.in(['error_request_otp', 'validating_otp'])
    },

    validatingOtp(): boolean {
      return this.uiState.is('validating_otp')
    },

    checkingStatus(): boolean {
      return this.uiState.is('checking_status')
    },

    organization() {
      return Organization.loggedIn()
    },

    creditLines(): CreditLine[] {
      return CreditLine.getForCurrentOrganization()
    },

    selectedCreditLine(this: any): CreditLine {
      return this.creditLines.find(
        (c: CreditLine) => c.id === this.selectedCreditLineId
      ) as CreditLine
    },

    deadlines(this: any): CreditLineDeadline[] {
      return this.selectedCreditLine ? this.selectedCreditLine.deadlines : []
    },

    groupedDeadlines(this: any) {
      return groupBy(sortBy(this.deadlines, 'timestamp'), 'dateDay')
    },

    creditLinesOptions(this: any) {
      return this.creditLines.map((creditLine: CreditLine) => ({
        label: creditLine.name,
        field: creditLine.id
      }))
    },

    activeMtnDeadline(this: any) {
      return (
        this.activeDeadlineId &&
        this.groupedDeadlines[this.activeDeadlineId].find(
          (d: CreditLineDeadline) => d.otm === 'mtn'
        )
      )
    },

    activeMoovDeadline(this: any) {
      return (
        this.activeDeadlineId &&
        this.groupedDeadlines[this.activeDeadlineId].find(
          (d: CreditLineDeadline) => d.otm === 'moov'
        )
      )
    },

    mtnAmount(this: any): number {
      return this.activeMtnDeadline &&
        this.activeMtnDeadline.state === 'pending'
        ? this.activeMtnDeadline.monthlyAmount
        : 0
    },

    moovAmount(this: any): number {
      return this.activeMoovDeadline &&
        this.activeMoovDeadline.state === 'pending'
        ? this.activeMoovDeadline.monthlyAmount
        : 0
    },
    moovBalance(this: any): number {
      return this.soldeRembourssementMoov
    },
    mtnBalance(this: any): number {
      return this.soldeRembourssementMtn
    },
    totalBalance(this: any): number {
      return this.moovBalance + this.mtnBalance
    }
  },

  watch: {
    'form.otm'(otm) {
      if (otm === 'mtn') {
        this.form.amount = this.mtnAmount
      } else {
        this.form.amount = this.moovAmount
      }
    }
  },

  created(this: any) {
    CreditLine.api().fetchForCurrentOrganization({ withDeadline: true })
  },

  mounted() {
    //this.fetchVirtualUpdatedAccountAmount()
  },

  methods: {
    deadlineRefunded(deadlines: any[]) {
      return deadlines.every((deadline: any) => deadline.state === 'paid')
    },

    onDeadlineClicked(this: any, day: string) {
      this.activeDeadlineId = day
      if (this.form.otm === 'mtn') {
        this.form.amount = this.mtnAmount
      } else {
        this.form.amount = this.moovAmount
      }
    },

    onSubmit(this: any, data: any) {
      if (data.amount === 0) {
        return
      }

      const deadline = this.groupedDeadlines[this.activeDeadlineId].find(
        (d: CreditLineDeadline) => d.otm === data.otm.toLowerCase()
      )

      this.uiState.set('requesting', deadline.id)
      this.makeRefundCreditLineDeadlineRequest()
    },

    makeRefundCreditLineDeadlineRequest(otp?: string) {
      if (otp) {
        this.uiState.set('validating_otp', this.uiState.data)
      }

      CreditLineDeadline.api()
        .refund(this.uiState.data, otp)
        .then(() => {
          this.uiState.set('transaction_successful')
          CreditLine.api().fetchForCurrentOrganization({ withDeadline: true })
          this.form.amount = 0
          Account.api().fetchAll()
        })
        .catch((error: any) => {
          if (error.response) {
            switch (error.response.status) {
              case 400:
                if (error.response.data.code === 'otp_invalid') {
                  this.uiState.set('error_request_otp', this.uiState.data)
                  this.$refs.otpModal.setError('Le code otp est invalide')
                }
                break
              case 403:
                if (error.response.data.code === 'need_confirmation_otp') {
                  this.uiState.set('error_request_otp', this.uiState.data)
                }
                break
              case 406: // low balance
                this.uiState.set('error_low_balance')
                break
            }
          }
        })
    }
  }
})
