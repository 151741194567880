






















































import Vue, { VueConstructor } from 'vue'
import { ValidationObserver } from 'vee-validate'

const defaultModel = () => ({
  amount: 0,
  otm: ''
})

interface ComponentWithValidator extends Vue {
  $refs: {
    validator: InstanceType<typeof ValidationObserver>
  }
}

export default (Vue as VueConstructor<ComponentWithValidator>).extend({
  props: {
    value: {
      type: Object,
      default: defaultModel
    },
    loading: Boolean
  },

  data() {
    return {
      model: {
        ...defaultModel(),
        ...this.value
      }
    }
  },

  watch: {
    value: {
      deep: true,
      handler(newValue) {
        if (newValue !== this.model) {
          this.model = newValue
        }
      }
    },

    model: {
      deep: true,
      handler(value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    onSubmit() {
      this.$emit('submit', this.model)
    }
  }
})
